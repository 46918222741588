"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.My18PassWidget = exports.My18PassDialog = void 0;
var My18PassDialog_1 = require("./public/My18PassDialog");
Object.defineProperty(exports, "My18PassDialog", { enumerable: true, get: function () { return My18PassDialog_1.My18PassDialog; } });
var My18PassWidget_1 = require("./public/My18PassWidget");
Object.defineProperty(exports, "My18PassWidget", { enumerable: true, get: function () { return My18PassWidget_1.My18PassWidget; } });
const siteUrl = document.head
    ?.querySelector("meta[name=provider]")
    ?.getAttribute("website") ?? "https://my18pass.com";
const iframeServiceWorker = document.createElement("iframe");
iframeServiceWorker.src = siteUrl + "/service-worker";
iframeServiceWorker.width = "0px";
iframeServiceWorker.height = "0px";
document.body.appendChild(iframeServiceWorker);
window.addEventListener("message", (e) => {
    if (e.data === "session-update") {
        document.cookie = "force-my18pass-refresh=1; path=/; samesite=strict;";
        console.info("reload: session-update, force refresh");
        document.location.reload();
    }
});
