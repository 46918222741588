"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSetIframeSrc = exports.isSetWindowLocationAction = exports.isReadyAction = exports.isAuthorizeAction = exports.isFederationAction = exports.isTestThirdPartyResponse = exports.isRedirectAction = void 0;
function isRedirectAction(action) {
    return action.type === "redirect";
}
exports.isRedirectAction = isRedirectAction;
function isTestThirdPartyResponse(action) {
    return action.type === "third-party";
}
exports.isTestThirdPartyResponse = isTestThirdPartyResponse;
function isFederationAction(action) {
    return action.type === "federation";
}
exports.isFederationAction = isFederationAction;
function isAuthorizeAction(action) {
    return action.type === "authorize";
}
exports.isAuthorizeAction = isAuthorizeAction;
function isReadyAction(action) {
    return action.type === "ready";
}
exports.isReadyAction = isReadyAction;
function isSetWindowLocationAction(action) {
    return action.type === "setWindowLocation";
}
exports.isSetWindowLocationAction = isSetWindowLocationAction;
function isSetIframeSrc(action) {
    return action.type === "setIframeSrc";
}
exports.isSetIframeSrc = isSetIframeSrc;
