"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    en: {
        translation: {
            consent: {
                scope: {
                    "example-new-scope": "english scope",
                },
            },
            email: {
                invalid_email: "Invalid email syntax",
                dark_domain: "Use your personal email address",
                rejected: "Rejected email address, contact the support",
            },
            validators: {
                errors: {
                    "invalid-email": "Invalid email",
                    "invalid-birthdate": "Invalid birthdate",
                    "invalid-password": "Password must be at least 7 characters long",
                },
            },
        },
    },
    fr: {
        translation: {
            consent: {
                scope: {
                    "example-new-scope": "scope francais",
                },
            },
            email: {
                invalid_email: "Adresse mail non valide",
                dark_domain: "Utilisez votre adresse personnelle",
                rejected: "Adresse refusée, contactez le support",
            },
            validators: {
                errors: {
                    "invalid-email": "Adresse mail non valide",
                    "invalid-birthdate": "Date de naissance invalide",
                    "invalid-password": "Le mot de passe doit avoir au moins 7 caractères",
                },
            },
        },
    },
};
